import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/NPC/Vehicles/Heavy Transport`;

// BODY
const bodyImage = `${imagePath}/Heavy Transport.png`;

// OPTION
const optionCargoImage = `${imagePath}/Accessory - Cargo.png`;
const optionCargoStripeImage = `${imagePath}/Accessory - Cargo Stripe.png`;
const optionMobileBaseImage = `${imagePath}/Accessory - Mobile Base.png`;
const optionMobileBaseScoutImage = `${imagePath}/Accessory - Mobile Base Scout.png`;
const optionRepairStationImage = `${imagePath}/Accessory - Repair Station.png`;
const optionScoutAirborneImage = `${imagePath}/Accessory - Scout Airborne.png`;
const optionWindowShadesImage = `${imagePath}/Accessory - Window Shades.png`;

// CAMO
const corproCamo1Image = `${imagePath}/Camo - Corpro 1.png`;
const corproCamo2Image = `${imagePath}/Camo - Corpro 2.png`;
const forestCamo1Image = `${imagePath}/Camo - Forest 1.png`;
const forestCamo2Image = `${imagePath}/Camo - Forest 2.png`;
const urbanCamo1Image = `${imagePath}/Camo - Urban 1.png`;
const urbanCamo2Image = `${imagePath}/Camo - Urban 2.png`;
const headCamoImage = `${imagePath}/Camo - Head.png`;
const tigerCamoImage = `${imagePath}/Camo - Tiger.png`;
const halftoneVertCamoImage = `${imagePath}/Camo - Halftone Vert.png`;
const halftoneDiagCamoImage = `${imagePath}/Camo - Halftone Diag.png`;
const survivorCamoImage = `${imagePath}/Camo - Survivor.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "56px",
    right: "10px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "NONE",
        "camo1Value": "NONE",
        "camo2Value": "NONE",
        "camo3Value": "NONE",
        "camo4Value": "NONE",

        "chassisTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "camo1TintValue": "none",
        "camo2TintValue": "none",
        "camo3TintValue": "none",
        "camo4TintValue": "none"
    }`,
    chassis: [
        {
            value: 0,
            label: "HEAVY TRANSPORT",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: [
        {
            value: 0,
            label: "CARGO",
            src: optionCargoImage,
        },
        {
            value: 1,
            label: "CARGO STRIPE",
            src: optionCargoStripeImage,
        },
        {
            value: 2,
            label: "MOBILE BASE",
            src: optionMobileBaseImage,
        },
        {
            value: 3,
            label: "MOBILE BASE SCOUT",
            src: optionMobileBaseScoutImage,
        },
        {
            value: 4,
            label: "REPAIR STATION",
            src: optionRepairStationImage,
        },
        {
            value: 5,
            label: "SCOUT AIRBORNE",
            src: optionScoutAirborneImage,
        },
        {
            value: 6,
            label: "WINDOW SHADES",
            src: optionWindowShadesImage,
        },
    ],
    secondaryWeapon: [
        {
            value: 0,
            label: "SCOUT AIRBORNE",
            src: optionScoutAirborneImage,
        },
        {
            value: 1,
            label: "WINDOW SHADES",
            src: optionWindowShadesImage,
        },
    ],
    camo: [
        {
            value: 0,
            label: "HEAD",
            src: headCamoImage,
        },
        {
            value: 1,
            label: "CORPRO 1",
            src: corproCamo1Image,
        },
        {
            value: 2,
            label: "CORPRO 2",
            src: corproCamo2Image,
        },
        {
            value: 3,
            label: "FOREST 1",
            src: forestCamo1Image,
            isPremium: true,
        },
        {
            value: 4,
            label: "FOREST 2",
            src: forestCamo2Image,
            isPremium: true,
        },
        {
            value: 5,
            label: "URBAN 1",
            src: urbanCamo1Image,
            isPremium: true,
        },
        {
            value: 6,
            label: "URBAN 2",
            src: urbanCamo2Image,
            isPremium: true,
        },
        {
            value: 7,
            label: "TIGER",
            src: tigerCamoImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "HALFTONE VERT",
            src: halftoneVertCamoImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "HALFTONE DIAG",
            src: halftoneDiagCamoImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "SURVIVOR",
            src: survivorCamoImage,
            isPremium: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "OPTION",
            value: "weapon",
        },
        {
            label: "OPTION 2",
            value: "secondaryWeapon",
        },
        {
            label: "CAMO 1",
            value: "camo1",
        },
        {
            label: "CAMO 2",
            value: "camo2",
        },
        {
            label: "CAMO 3",
            value: "camo3",
        },
        {
            label: "CAMO 4",
            value: "camo4",
        },
    ],
    labels: {
        "chassis": "CHASSIS",
        "weapon": "OPTION",
        "secondaryWeapon": "OPTION 2",
        "camo1": "CAMO 1",
        "camo2": "CAMO 2",
        "camo3": "CAMO 3",
        "camo4": "CAMO 4",
    },
};
