import apc from "./Mechs-Vehicles-APC";
import buggy from "./Mechs-Vehicles-Buggy";
import dropship from "./Mechs-Vehicles-Dropship";
import MechsVehiclesHeavyTransport from "./Mechs-Vehicles-Heavy-Transport";
import MechsVehiclesMotorcycle from "./Mechs-Vehicles-Motorcycle";
import MechsVehiclesTechnical from "./Mechs-Vehicles-Technical";

export default [
    Object.assign({ value: 0, label: "APC" }, apc),
    Object.assign({ value: 1, label: "BUGGY" }, buggy),
    Object.assign({ value: 2, label: "DROPSHIP" }, dropship),
    Object.assign({ value: 3, label: "HEAVY TRANSPORT" }, MechsVehiclesHeavyTransport),
    Object.assign({ value: 4, label: "MOTORCYCLE" }, MechsVehiclesMotorcycle),
    Object.assign({ value: 5, label: "TECHNICAL" }, MechsVehiclesTechnical),
];

export const vehicleTemplateList = [
    {
        label: "DEFAULT",
        value: {"camo1Value":"NONE","camo2Value":"NONE","camo3Value":"NONE","camo4Value":"NONE","camo1TintValue":"none","camo2TintValue":"none","camo3TintValue":"none","camo4TintValue":"none","chassisTintValue":"none","weaponTintValue":"none","secondaryWeaponTintValue":"none"},
    },
    {
        label: "BUBBLEGUM CRISIS",
        value: {"camo1Value":"5","camo2Value":"0","camo3Value":"3","camo4Value":"8","camo1TintValue":"sepia(100%) saturate(300%) brightness(172%) hue-rotate(300deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(65%) saturate(312%) brightness(200%) hue-rotate(150deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(65%) saturate(263%) brightness(130%) hue-rotate(150deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(100%) saturate(151%) brightness(97%) hue-rotate(261deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(66%) saturate(160%) brightness(98%) hue-rotate(189deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(65%) saturate(424%) brightness(200%) hue-rotate(286deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(300%) brightness(181%) hue-rotate(332deg) invert(0%) contrast(100%)"},
    },
    {
        label: "TUNDRA SLATE",
        value: {"camo1Value":"0","camo2Value":"3","camo3Value":"6","camo4Value":"4","camo1TintValue":"sepia(100%) saturate(69%) brightness(200%) hue-rotate(55deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(100%) saturate(62%) brightness(43%) hue-rotate(141deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(62%) brightness(111%) hue-rotate(141deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(100%) saturate(103%) brightness(43%) hue-rotate(141deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(100%) saturate(103%) brightness(81%) hue-rotate(141deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(68%) saturate(89%) brightness(142%) hue-rotate(104deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(68%) saturate(366%) brightness(130%) hue-rotate(116deg) invert(0%) contrast(100%)"},
    },
    {
        label: "CHARCOAL SHERBET",
        value: {"camo1Value":"NONE","camo2Value":"2","camo3Value":"5","camo4Value":"6","camo1TintValue":"none","camo2TintValue":"sepia(100%) saturate(448%) brightness(160%) hue-rotate(318deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(0%) brightness(40%) hue-rotate(345deg) invert(0%) contrast(100%)","camo4TintValue":"none","chassisTintValue":"sepia(100%) saturate(0%) brightness(44%) hue-rotate(300deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(100%) saturate(38%) brightness(160%) hue-rotate(347deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(500%) brightness(108%) hue-rotate(320deg) invert(0%) contrast(100%)"},
    },
    {
        label: "FERROUS OXIDE",
        value: {"camo1Value":"3","camo2Value":"0","camo3Value":"6","camo4Value":"9","camo1TintValue":"sepia(100%) saturate(364%) brightness(66%) hue-rotate(316deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(100%) saturate(500%) brightness(54%) hue-rotate(305deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(260%) brightness(85%) hue-rotate(337deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(72%) saturate(265%) brightness(97%) hue-rotate(336deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(33%) saturate(239%) brightness(74%) hue-rotate(308deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(100%) saturate(69%) brightness(129%) hue-rotate(337deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(500%) brightness(118%) hue-rotate(325deg) invert(0%) contrast(100%)"},
    },
    {
        label: "HEDGE KNIGHT",
        value: {"camo1Value":"0","camo2Value":"4","camo3Value":"3","camo4Value":"9","camo1TintValue":"sepia(48%) saturate(0%) brightness(187%) hue-rotate(318deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(40%) saturate(352%) brightness(60%) hue-rotate(357deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(48%) saturate(308%) brightness(104%) hue-rotate(360deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(48%) saturate(373%) brightness(44%) hue-rotate(95deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(64%) saturate(282%) brightness(84%) hue-rotate(43deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(48%) saturate(185%) brightness(63%) hue-rotate(74deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(241%) brightness(137%) hue-rotate(328deg) invert(0%) contrast(100%)"},
    },
];
