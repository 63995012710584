import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/MO_S-Celeste`;

// BODY
const bodyImage = `${imagePath}/Celeste Chassis.png`;

// HEADS
const headCelesteImage = `${imagePath}/heads/Heads - Celeste.png`;
const headCelesteblankImage = `${imagePath}/heads/Heads - Celeste blank.png`;
const headAmberPhantomImage = `${imagePath}/heads/Heads - Amber Phantom.png`;
const headAngularImage = `${imagePath}/heads/Heads - Angular.png`;
const headBriarImage = `${imagePath}/heads/Heads - Briar.png`;
const headCalibanImage = `${imagePath}/heads/Heads - Caliban.png`;
const headHornedImage = `${imagePath}/heads/Heads - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Heads - Horned Hair.png`;
const headKazuImage = `${imagePath}/heads/Heads - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Heads - Laborer.png`;
const headMaliceImage = `${imagePath}/heads/Heads - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Heads - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Heads - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Heads - Mecha 3.png`;
const headMecha5Image = `${imagePath}/heads/Heads - Mecha 5.png`;
const headNaturalLawImage = `${imagePath}/heads/Heads - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Heads - Natural Law Hair.png`;
const headOgreImage = `${imagePath}/heads/Heads - Ogre.png`;
const headPaladinImage = `${imagePath}/heads/Heads - Paladin.png`;
const headPartisanImage = `${imagePath}/heads/Heads - Partisan.png`;
const headPredatorImage = `${imagePath}/heads/Heads - Predator.png`;
const headRainTransformedImage = `${imagePath}/heads/Heads - Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Heads - Rain Transformed Hair.png`;
const headScarabImage = `${imagePath}/heads/Heads - Scarab.png`;
const headScopedogImage = `${imagePath}/heads/Heads - Scopedog.png`;
const headStalwartImage = `${imagePath}/heads/Heads - Stalwart.png`;
const headVanguardImage = `${imagePath}/heads/Heads - Vanguard.png`;
const headVisionaryImage = `${imagePath}/heads/Heads - Visionary.png`;

// WEAPONS
const weaponsCannibalShotgunImage = `${imagePath}/weapons/Weapons - Cannibal Shotgun.png`;
const weaponsEREBUSShotgunImage = `${imagePath}/weapons/Weapons - EREBUS Shotgun.png`;
const weaponsP91Image = `${imagePath}/weapons/Weapons - P91.png`;
const weaponsShotgun2Image = `${imagePath}/weapons/Weapons - Shotgun 2.png`;
const weaponsTerrorClassShotgunImage = `${imagePath}/weapons/Weapons - Terror-Class Shotgun.png`;
const weaponsTerrorClassShotgunHIMImage = `${imagePath}/weapons/Weapons - Terror-Class Shotgun HIM.png`;

// REAR MOUNT
const rearBoostersImage = `${imagePath}/accessories/Accessories - Boosters.png`;
const rearGravCompensatorsImage = `${imagePath}/accessories/Accessories - Grav Compensators.png`;
const rearShotgunsImage = `${imagePath}/accessories/Accessories - Shotguns.png`;

// GLOWS
const glowBoostersImage = `${imagePath}/glows/Glows - Boosters.png`;
const glowCalibanHeadImage = `${imagePath}/glows/Glows - Caliban Head.png`;
const glowCelesteHeadImage = `${imagePath}/glows/Glows - Celeste Head.png`;
const glowEREBUScannonImage = `${imagePath}/glows/Glows - EREBUS cannon.png`;
const glowGravCompensatorsImage = `${imagePath}/glows/Glows - Grav Compensators.png`;
const glowP91LaserSightImage = `${imagePath}/glows/Glows - P91 Laser Sight.png`;

const previewImage = `${imagePath}/preview.png`;

const coreImage = `${imagePath}/Celeste_by_gummies139.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "62px",
    right: "49px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Gummies139",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "5",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "2",
        "accessory2Value": "0",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "CELESTE",
            src: headCelesteImage,
        },
        {
            value: 1,
            label: "CELESTE - BLANK",
            src: headCelesteblankImage,
        },
        {
            value: 2,
            label: "AMBER PHANTOM",
            src: headAmberPhantomImage,
        },
        {
            value: 3,
            label: "ANGULAR",
            src: headAngularImage,
        },
        {
            value: 4,
            label: "BRIAR",
            src: headBriarImage,
        },
        {
            value: 5,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 6,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 7,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 8,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 9,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 10,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 11,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 12,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 13,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 14,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 15,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 16,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 17,
            label: "OGRE",
            src: headOgreImage,
        },
        {
            value: 18,
            label: "PALADIN",
            src: headPaladinImage,
        },
        {
            value: 19,
            label: "PARTISAN",
            src: headPartisanImage,
        },
        {
            value: 20,
            label: "PREDATOR",
            src: headPredatorImage,
        },
        {
            value: 21,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 22,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 23,
            label: "SCARAB",
            src: headScarabImage,
        },
        {
            value: 24,
            label: "SCOPEDOG",
            src: headScopedogImage,
        },
        {
            value: 25,
            label: "STALWART",
            src: headStalwartImage,
        },
        {
            value: 26,
            label: "VANGUARD",
            src: headVanguardImage,
        },
        {
            value: 27,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "MO&S CELESTE",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CANNIBAL SHOTGUN",
                src: weaponsCannibalShotgunImage,
            },
            {
                value: 1,
                label: "EREBUS + SHOTGUN",
                src: weaponsEREBUSShotgunImage,
            },
            {
                value: 2,
                label: "P91",
                src: weaponsP91Image,
            },
            {
                value: 3,
                label: "SHOTGUN 2",
                src: weaponsShotgun2Image,
            },
            {
                value: 4,
                label: "TERROR CLASS SHOTGUN",
                src: weaponsTerrorClassShotgunImage,
            },
            {
                value: 5,
                label: "TERROR CLASS SHOTGUN HIM",
                src: weaponsTerrorClassShotgunHIMImage,
            },
        ],
    },
    secondaryWeapon: [],
    rearMount: [
        {
            value: 0,
            label: "BOOSTERS",
            src: rearBoostersImage,
        },
        {
            value: 1,
            label: "GRAV COMPENSATORS",
            src: rearGravCompensatorsImage,
        },
        {
            value: 2,
            label: "SHOTGUNS",
            src: rearShotgunsImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "BOOSTERS",
            src: glowBoostersImage,
        },
        {
            value: 1,
            label: "CALIBAN HEAD",
            frontSrc: glowCalibanHeadImage,
        },
        {
            value: 2,
            label: "CELESTE HEAD",
            frontSrc: glowCelesteHeadImage,
        },
        {
            value: 3,
            label: "EREBUS CANNON",
            frontSrc: glowEREBUScannonImage,
        },
        {
            value: 4,
            label: "GRAV COMPENSATORS",
            src: glowGravCompensatorsImage,
        },
        {
            value: 5,
            label: "P91 LASER SIGHT",
            frontSrc: glowP91LaserSightImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "EMPTY",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
