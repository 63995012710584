import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/AF-Anckarstrom`;

// BODY
const bodyImage = `${imagePath}/Chassis.png`;

// HEADS
const headAnckarstromImage = `${imagePath}/heads/Head - Anckarstrom.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDomImage = `${imagePath}/heads/Head - Dom.png`;
const headDomeImage = `${imagePath}/heads/Head - Dome.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headEnkiduImage = `${imagePath}/heads/Head - Enkidu.png`;
const headFTNelsonImage = `${imagePath}/heads/Head - FT Nelson.png`;
const headHoodImage = `${imagePath}/heads/Head - Hood.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headLycanImage = `${imagePath}/heads/Head - Lycan.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainImage = `${imagePath}/heads/Head - Rain.png`;
const headRainHairImage = `${imagePath}/heads/Head - Rain Hair.png`;
const headRinzlerImage = `${imagePath}/heads/Head - Rinzler.png`;
const headSkull3Image = `${imagePath}/heads/Head - Skull 3.png`;
const headTokugawaImage = `${imagePath}/heads/Head - Tokugawa.png`;
const headTricornImage = `${imagePath}/heads/Head - Tricorn.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
// LEFT
const weaponsLeftCleaverteethImage = `${imagePath}/left/Left Weapon Down - Cleaver teeth.png`;
const weaponsLeftCleaverImage = `${imagePath}/left/Left Weapon Down - Cleaver.png`;
const weaponsLeftFractalbladeRapierImage = `${imagePath}/left/Left Weapon Straight - Fractalblade Rapier.png`;
const weaponsLeftKatanaImage = `${imagePath}/left/Left Weapon Straight - Katana.png`;
const weaponsLeftMatchLOKPistol1Image = `${imagePath}/left/Left Weapon Straight - MatchLOK Pistol 1.png`;
const weaponsLeftMatchLOKPistol2Image = `${imagePath}/left/Left Weapon Straight - MatchLOK Pistol 2.png`;
const weaponsLeftShotgunImage = `${imagePath}/left/Left Weapon Straight - Shotgun.png`;
const weaponsLeftSMGImage = `${imagePath}/left/Left Weapon Straight - SMG.png`;
const weaponsLeftTorchImage = `${imagePath}/left/Left Weapon Straight - Torch.png`;
const weaponsLeftTribarrelShotgunImage = `${imagePath}/left/Left Weapon Straight - Tribarrel Shotgun.png`;
const weaponsLeftVariableSwordCurveImage = `${imagePath}/left/Left Weapon Straight - Variable Sword Curve.png`;
const weaponsLeftVariableSwordStraightImage = `${imagePath}/left/Left Weapon Straight - Variable Sword Straight.png`;
// RIGHT
const weaponsRightKatanaScabbardImage = `${imagePath}/right/Right Weapon Down - Katana Scabbard.png`;
const weaponsRightMatchLOKPistolImage = `${imagePath}/right/Right Weapon Up - MatchLOK Pistol.png`;
const weaponsRightSheathedRapierImage = `${imagePath}/right/Right Weapon Down - Sheathed Rapier.png`;

// REAR MOUNT
const rearCloakImage = `${imagePath}/accessories/Accessories - Cloak.png`;
const rearCloakBackImage = `${imagePath}/accessories/Accessory Under - Cloak.png`;

// GLOW
const glowChassisarmdownImage = `${imagePath}/glow/Glow - Chassis arm down.png`;
const glowChassisarmupImage = `${imagePath}/glow/Glow - Chassis arm up.png`;
const glowFractalbladeImage = `${imagePath}/glow/Glow - Fractalblade.png`;
const glowKatanaImage = `${imagePath}/glow/Glow - Katana.png`;
const glowMatchLOKQuickdrawImage = `${imagePath}/glow/Glow - MatchLOK Quickdraw.png`;
const glowTargettingImage = `${imagePath}/glow/Glow - Targetting.png`;
const glowTorchImage = `${imagePath}/glow/Glow - Torch.png`;
const glowVariableSwordCurvedImage = `${imagePath}/glow/Glow - Variable Sword Curved.png`;
const glowVariableSwordStraightImage = `${imagePath}/glow/Glow - Variable Sword Straight.png`;

// ACCESSORY
const accChestHolderImage = `${imagePath}/accessories/Accessories - Chest Holder.png`;
const accChestHolderemptyImage = `${imagePath}/accessories/Accessories - Chest Holder empty.png`;
const accHipHolderImage = `${imagePath}/accessories/Accessories - Hip Holder.png`;
const accHipHolderemptyImage = `${imagePath}/accessories/Accessories - Hip Holder empty.png`;
const accKatanaScabbardImage = `${imagePath}/accessories/Accessories - Katana Scabbard.png`;
const accRapierScabbardImage = `${imagePath}/accessories/Accessories - Rapier Scabbard.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "19px",
    right: "-20px",
};

export default {
    previewImg: previewImage,
    isMegadeus: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "1",
        "weaponValue": "2",
        "secondaryWeaponValue": "1",
        "accessory1Value": "2",
        "accessory2Value": "NONE",
        "accessory3Value": "9",
        "accessory4Value": "11",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ANCKARSTROM",
            foremostSrc: headAnckarstromImage,
        },
        {
            value: 1,
            label: "BLACKSPOT",
            foremostSrc: headBlackspotImage,
        },
        {
            value: 2,
            label: "CALIBAN",
            foremostSrc: headCalibanImage,
        },
        {
            value: 3,
            label: "CHEDDAH",
            foremostSrc: headCheddahImage,
        },
        {
            value: 4,
            label: "CHIMERA",
            foremostSrc: headChimeraImage,
        },
        {
            value: 5,
            label: "CYCLOPS",
            foremostSrc: headCyclopsImage,
        },
        {
            value: 6,
            label: "DOM",
            foremostSrc: headDomImage,
        },
        {
            value: 7,
            label: "DOME",
            foremostSrc: headDomeImage,
        },
        {
            value: 8,
            label: "DUNGAM",
            foremostSrc: headDungamImage,
        },
        {
            value: 9,
            label: "ENKIDU",
            foremostSrc: headEnkiduImage,
        },
        {
            value: 10,
            label: "FT NELSON",
            foremostSrc: headFTNelsonImage,
        },
        {
            value: 11,
            label: "HOOD",
            foremostSrc: headHoodImage,
        },
        {
            value: 12,
            label: "HORNED",
            foremostSrc: headHornedImage,
        },
        {
            value: 13,
            label: "HORNED HAIR",
            foremostSrc: headHornedHairImage,
        },
        {
            value: 14,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 15,
            label: "LABORER",
            foremostSrc: headLaborerImage,
        },
        {
            value: 16,
            label: "LYCAN",
            foremostSrc: headLycanImage,
        },
        {
            value: 17,
            label: "MAG",
            foremostSrc: headMagImage,
        },
        {
            value: 18,
            label: "MALICE",
            foremostSrc: headMaliceImage,
        },
        {
            value: 19,
            label: "MECHA 1",
            foremostSrc: headMecha1Image,
        },
        {
            value: 20,
            label: "MECHA 2",
            foremostSrc: headMecha2Image,
        },
        {
            value: 21,
            label: "MECHA 3",
            foremostSrc: headMecha3Image,
        },
        {
            value: 22,
            label: "MECHA 4",
            foremostSrc: headMecha4Image,
        },
        {
            value: 23,
            label: "MECHA 5",
            foremostSrc: headMecha5Image,
        },
        {
            value: 24,
            label: "MECHA 6",
            foremostSrc: headMecha6Image,
        },
        {
            value: 25,
            label: "MECHA 7",
            foremostSrc: headMecha7Image,
        },
        {
            value: 26,
            label: "MECHA 8",
            foremostSrc: headMecha8Image,
        },
        {
            value: 27,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
        },
        {
            value: 28,
            label: "NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
        },
        {
            value: 29,
            label: "NEMESIS",
            foremostSrc: headNemesisImage,
        },
        {
            value: 30,
            label: "RAIN",
            foremostSrc: headRainImage,
        },
        {
            value: 31,
            label: "RAIN HAIR",
            foremostSrc: headRainHairImage,
        },
        {
            value: 32,
            label: "RINZLER",
            foremostSrc: headRinzlerImage,
        },
        {
            value: 33,
            label: "SKULL 3",
            foremostSrc: headSkull3Image,
        },
        {
            value: 34,
            label: "TOKUGAWA",
            foremostSrc: headTokugawaImage,
        },
        {
            value: 35,
            label: "TRICORN",
            foremostSrc: headTricornImage,
        },
        {
            value: 36,
            label: "VANQUISHER",
            foremostSrc: headVanquisherImage,
        },
        {
            value: 37,
            label: "VICEROY",
            foremostSrc: headViceroyImage,
        },
        {
            value: 38,
            label: "VISIONARY",
            foremostSrc: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "AF ANCKARSTROM",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by MuninnUmber ",
                title: "Field Guide to the Dead Rings",
                url: "https://munninumber.itch.io/field-guide-to-the-dead-rings",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CLEAVER",
                backSrc: weaponsLeftCleaverImage,
            },
            {
                value: 1,
                label: "CLEAVER TEETH",
                backSrc: weaponsLeftCleaverteethImage,
            },
            {
                value: 2,
                label: "FRACTALBLADE RAPIER",
                backSrc: weaponsLeftFractalbladeRapierImage,
            },
            {
                value: 3,
                label: "KATANA",
                backSrc: weaponsLeftKatanaImage,
            },
            {
                value: 4,
                label: "MATCHLOK PISTOL 1",
                backSrc: weaponsLeftMatchLOKPistol1Image,
            },
            {
                value: 5,
                label: "MATCHLOK PISTOL 2",
                backSrc: weaponsLeftMatchLOKPistol2Image,
            },
            {
                value: 6,
                label: "SHOTGUN",
                backSrc: weaponsLeftShotgunImage,
            },
            {
                value: 7,
                label: "SMG",
                backSrc: weaponsLeftSMGImage,
            },
            {
                value: 8,
                label: "TORCH",
                backSrc: weaponsLeftTorchImage,
            },
            {
                value: 9,
                label: "TRIBARREL SHOTGUN",
                backSrc: weaponsLeftTribarrelShotgunImage,
            },
            {
                value: 10,
                label: "VARIABLE SWORD - CURVE",
                backSrc: weaponsLeftVariableSwordCurveImage,
            },
            {
                value: 11,
                label: "VARIABLE SWORD - STRAIGHT",
                backSrc: weaponsLeftVariableSwordStraightImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "KATANA SCABBARD",
            backSrc: weaponsRightKatanaScabbardImage,
        },
        {
            value: 1,
            label: "MATCHLOK PISTOL",
            backSrc: weaponsRightMatchLOKPistolImage,
        },
        {
            value: 2,
            label: "SHEATHED RAPIER",
            backSrc: weaponsRightSheathedRapierImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "CHEST HOLSTER",
            frontSrc: accChestHolderImage,
        },
        {
            value: 1,
            label: "CHEST HOLSTER EMPTY",
            frontSrc: accChestHolderemptyImage,
        },
        {
            value: 2,
            label: "HIP HOLSTER",
            frontSrc: accHipHolderImage,
        },
        {
            value: 3,
            label: "HIP HOLSTER EMPTY",
            frontSrc: accHipHolderemptyImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CHASSIS ARM DOWN",
            src: glowChassisarmdownImage,
        },
        {
            value: 1,
            label: "CHASSIS ARM UP",
            src: glowChassisarmupImage,
        },
        {
            value: 2,
            label: "FRACTAL BLADE",
            frontSrc: glowFractalbladeImage,
        },
        {
            value: 3,
            label: "KATANA",
            frontSrc: glowKatanaImage,
        },
        {
            value: 4,
            label: "MATCHLOK QUICKDRAW",
            frontSrc: glowMatchLOKQuickdrawImage,
        },
        {
            value: 5,
            label: "TARGETTING",
            frontSrc: glowTargettingImage,
        },
        {
            value: 6,
            label: "TORCH",
            frontSrc: glowTorchImage,
        },
        {
            value: 7,
            label: "VARIABLE SWORD CURVED",
            foremostSrc: glowVariableSwordCurvedImage,
        },
        {
            value: 8,
            label: "VARIABLE SWORD STRAIGHT",
            frontSrc: glowVariableSwordStraightImage,
        },
        {
            value: 9,
            label: "ACCESSORY - CLOAK",
            frontSrc: rearCloakImage,
            backSrc: rearCloakBackImage,
        },
        {
            value: 10,
            label: "ACCESSORY - KATANA SCABBARD",
            src: accKatanaScabbardImage,
        },
        {
            value: 11,
            label: "ACCESSORY - RAPIER SCABBARD",
            src: accRapierScabbardImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS / ACCESSORIES",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L WEAPON",
            value: "weapon",
        },
        {
            label: "R WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "HOLSTER",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "ACCESSORY 1",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "HOLSTER",
        "weapon": "L WEAPON",
        "secondaryWeapon": "R WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "ACCESSORY 1",
        "accessory4": "ACCESSORY 2",
    },
};
